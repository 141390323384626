/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Drawer, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Context from '@/store/context';
import { getNavPath, getUrl } from '@/utils';

import './index.less';

const { SubMenu } = Menu;

/**
 * 递归拼接导航栏
 *
 * @param {ChannelType[]} navList 原始数据
 * @param {{id: number; name: string;}[]} navPath 已选
 */
const renderNav = (navList: ChannelType[]) => (
  <>
    {
      navList.map((item: ChannelType) => {
        if (item.ChannelType?.name === '配置' && item.showStatus !== 0) {
          return (
            <Menu.Item key={item.enName}>
              <a
                href={item.url}
                target={item.url?.startsWith('http') ? '_black' : '_self'}
                rel="noreferrer"
              >
                {item.name}
              </a>
            </Menu.Item>
          );
        }
        if (item.ChannelType?.name === '外部链接') {
          return (
            <Menu.Item key={item.enName}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
            </Menu.Item>
          );
        }
        if (item.children && item.children.length && item.showStatus !== 0) {
          return (
            <SubMenu key={item.enName} title={item.name}>
              {renderNav(item.children)}
            </SubMenu>
          );
        }
        const url = getUrl(item);
        return (
          <Menu.Item key={item.enName}>
            <a href={url}>{item.name}</a>
          </Menu.Item>
        );
      })
    }
  </>
);

/**
 *
 * @param {ChannelType[]} navList 下边拼接
 */
const showStateNav = (navList: ChannelType[]) => (
  <>
    {
       navList.map((item: ChannelType, key) => (
         // eslint-disable-next-line react/no-array-index-key
         <Menu.Item key={key}>
           <a
             href={item.url}
             target={item.url?.startsWith('http') ? '_black' : '_self'}
             rel="noreferrer"
           >
             {item.name}
           </a>
         </Menu.Item>
       ))
    }
  </>
);
const MobileNavList: FC = () => {
  const [visible, setVisible] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { navList, navPath, commonSettings } = state;
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectKeys, setSelectKeys] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [commonSettingNav, setCommonSetting] = useState<any[]>([]);
  useEffect(() => {
    const paths = navPath.map((nav) => nav.enName);
    setOpenKeys(paths);
    setSelectKeys([paths.reverse()[0]]);
    const commonSetting = commonSettings
      .filter((e: ChannelSettingType) => e.group === 'topright')
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((e: ChannelSettingType) => ({ id: e.id, name: e.title, url: e.link }));
    setCommonSetting(commonSetting);
  }, [commonSettings, navPath]);
  const rootSubmenuKeys = navList.map((nav) => nav.enName);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  // 点击菜单
  const onOpenChange = (keys: unknown) => {
    const latestOpenKey = (keys as string[]).find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey || '') === -1) {
      setOpenKeys(keys as string[]);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onClick = ({ key }: {
    key: React.Key;
    keyPath: React.Key[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement>;
  }) => {
    setVisible(false);
    const newPath = getNavPath(navList, key as string);
    const currentNav = newPath[0];
    if (!currentNav) return;
    if (currentNav.ChannelType?.name === '配置' && currentNav.url?.startsWith('http')) return;
    if (currentNav.ChannelType?.name === '外部链接') return;
    dispatch && dispatch({ type: 'SET_SELECT', navPath: newPath.reverse() });
    setSelectKeys([key as string]);
  };
  return (
    <>
      <MenuOutlined onClick={showDrawer} />
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectKeys}
          onOpenChange={onOpenChange}
          onClick={onClick}
        >
          {
            renderNav(navList)
          }
          {
            showStateNav(commonSettingNav)
          }
        </Menu>
      </Drawer>
    </>
  );
};

export default MobileNavList;
