import './polyfill';
import 'core-js/es';
import 'mutation-observer';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import CommonLayout from './layout';
import * as serviceWorker from './serviceWorker';

import './index.less';

ReactDOM.render(
  <React.StrictMode>
    <CommonLayout />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
