/*
* @description: 统一状态维护
* @author: zpl
* @Date: 2020-09-24 11:08:40
 * @LastEditTime: 2021-04-25 17:05:05
 * @LastEditors: zpl
*/
import { getUrl } from '@/utils';
import { indexNav } from '../constant';

/**
 * 导航状态
 *
 * @template NavType
 * @param {NavType} state
 * @param {NavReaducerType} acttion
 * @returns {NavType}
 */
export const commonReducer = <CommonStateType>(
  state: CommonStateType,
  acttion: CommonReaducerType,
): CommonStateType => {
  const currentNav = acttion.navPath?.length ? acttion.navPath[acttion.navPath.length - 1] : indexNav;
  const url = getUrl(currentNav);
  // 从栏目文章列表中转到栏目中某一篇文章内容时，在文章内容组件中会自动给地址追加文章ID，此时不需要在这里再次设置地址栏
  if (!window.location.pathname.includes(url)) {
    window.history.pushState('', '', url);
  }

  switch (acttion.type) {
    case 'SET_LIST':
      return {
        ...state,
        navList: acttion.navList || [],
        navPath: acttion.navPath,
      };
    case 'SET_SELECT':
      return {
        ...state,
        navPath: acttion.navPath,
      };
    case 'SET_COMMON_SETTINGS':
      return {
        ...state,
        commonSettings: acttion.commonSettings,
      };
    default:
      return state;
  }
};

export const test = {};
