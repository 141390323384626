/** !
 * @description: 顶部banner轮播
 * @author: zpl
 * @Date: 2020-09-22 16:47:37
 * @LastEditTime: 2020-09-22 16:47:40
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Carousel, Spin } from 'antd';
import { sourceUrl } from '@/constant';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

interface PropsType {
  settings: ChannelSettingType[],
}
const Banner: FC<PropsType> = ({ settings = [] }: PropsType) => {
  if (settings.length) {
    return (
      <Carousel
        className="bannerCon"
        dots={false}
        autoplay
      >
        {settings
          .filter((setting) => setting.group === 'banner')
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((setting) => ({
            url: setting.link,
            src: setting.pic,
          }))
          .map((banner) => (
            <a
              key={banner.src}
              href={banner.url}
            >
              <img
                src={`${banner.src.startsWith('http') ? banner.src : (sourceUrl + banner.src)}`}
                alt="banner"
                width="100%"
              />
            </a>
          ))}
      </Carousel>
    );
  }
  return <Spin style={{ display: 'block', margin: '0 auto', padding: '5rem' }} indicator={antIcon} />;
};

export default Banner;
