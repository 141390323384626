/** !
 * @description: 协会动态
 * @author: zpl
 * @Date: 2020-09-23 14:28:46
 * @LastEditTime: 2020-09-23 14:31:18
 * @LastEditors: zpl
 */
import React, { FC, useEffect, useState } from 'react';
import { Card, List, Skeleton } from 'antd';

import './index.less';

interface PropsType {
  dataList: { title: string; id: string; conDate: string; }[];
}

const News: FC<PropsType> = ({ dataList = [] }: PropsType) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (dataList.length) {
      setLoading(false);
    }
  }, [dataList]);
  return (
    <Card
      className="assCon"
      title="协会动态"
      bordered={false}
      extra={<a href="/channel/xkdt">更多</a>}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={dataList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={(
                  <a
                    href={`/channel/xkdt#${item.id}`}
                  >
                    {item.title}
                  </a>
                      )}
              />
              <div className="date">{item.conDate.split('T')[0].split(' ')[0]}</div>
            </List.Item>
          )}
        />
      </Skeleton>
    </Card>
  );
};

export default News;
