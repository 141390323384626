/** !
 * @description: 图片列表的二级导航
 * @author: zpl
 * @Date: 2020-09-24 14:57:06
 * @LastEditTime: 2020-09-24 14:57:15
 * @LastEditors: zpl
 */
import React, { FC, useContext } from 'react';
import { Col, Row } from 'antd';

import { findChildrenNav, getNavPath } from '@/utils';
import Context from '@/store/context';
import { indexNav } from '@/constant';

import './index.less';

const SecendNav: FC = () => {
  const { state: { navList, navPath }, dispatch } = useContext(Context);
  const parent = navPath.length > 1 ? navPath[1] : indexNav;
  const currentChildren = findChildrenNav(navList, parent.id);
  return (
    <Row className="picNavCon">
      <Col flex="5em">{`${parent.name}:`}</Col>
      <Col flex="1 1 500px">
        <Row gutter={10}>
          {
            currentChildren.map((child) => (
              <Col
                key={child.id}
                onClick={() => {
                  const newPath = getNavPath(navList, child.enName);
                  newPath.push(indexNav);
                  dispatch && dispatch({ type: 'SET_SELECT', navPath: newPath.reverse() });
                }}
              >
                <div className={window.location.pathname.split('/')[2] === child.enName ? 'active item' : 'item'}>
                  {child.name}
                </div>
              </Col>
            ))
          }
        </Row>
      </Col>
    </Row>
  );
};

export default SecendNav;
