/** !
 * @description: 图文列表
 * @author: zpl
 * @Date: 2020-09-24 10:20:08
 * @LastEditTime: 2020-09-24 10:20:14
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Row, Col, Empty, List, Pagination, Image } from 'antd';

import Context from '@/store/context';
import { getPubList } from '@/api';
import { getSettingsForChannel } from '@/utils';
import SecondNav from '@components/SecondNav';
import QuickEntry from '@components/QuickEntry';

import './index.less';
import { defImg } from '@/constant';

const itemRender = (current: number, type: string, originalElement: React.ReactElement) => {
  if (type === 'prev') {
    return <a>上一页</a>;
  }
  if (type === 'next') {
    return <a>下一页</a>;
  }
  return originalElement;
};

const Desc: FC<{ summary: string; conDate: string }> = ({ summary, conDate }: { summary: string; conDate: string }) => (
  <>
    <div className="summary">{summary}</div>
    <div className="conDate">{conDate.split(' ')[0]}</div>
  </>
);

interface PropType {
  channelId: string;
  channelName: string;
  enName: string;
}
const ArticleList: FC<PropType> = ({ channelId, channelName, enName }: PropType) => {
  const { state: { navList, commonSettings } } = useContext(Context);
  const [current, setCurrent] = useState(1);
  const pageSize = 20;
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<ListItmeType[]>([]);
  const [settings, setSettings] = useState<ChannelSettingType[]>([]);

  useEffect(() => {
    // 获取装饰配置
    const currentSettings = getSettingsForChannel(commonSettings, navList, enName);
    setSettings(currentSettings);
  }, [commonSettings, navList, enName]);

  useEffect(() => {
    (async () => {
      const data = await getPubList(channelId, current, pageSize);
      // 翻页的时候置顶
      document.documentElement.scrollTop = 0;
      setTotal(data.total);
      setList(data.list);
    })();
  }, [channelId, current, pageSize]);

  return (
    <Row gutter={30} className="articlePicCon">
      <Col flex="320px" className="leftCon">
        <SecondNav />
        <div className="left-bottom">
          <QuickEntry settings={settings} column={2} />
        </div>
      </Col>

      <Col flex="auto" className="rightCon">
        <div className="topTitle">
          <span>{channelName}</span>
        </div>
        {
          list.length
            ? (
              <>
                <List
                  className="articlePicListCon"
                  size="small"
                  itemLayout="horizontal"
                  dataSource={list}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={(
                          <div className="listThumbnail">
                            <Image
                              alt="logo"
                              src={item.thumbnail || defImg}
                              preview={false}
                            />
                          </div>
                        )}
                        title={
                          <a href={`/channel/${enName}#${item.id}`} target="_blank" rel="noreferrer">{item.title}</a>
                        }
                        description={
                          <Desc summary={item.summary} conDate={item.conDate.split('T')[0].split(' ')[0]} />
                        }
                      />
                    </List.Item>
                  )}
                />
                <Pagination
                  size="small"
                  total={total}
                  current={current}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  itemRender={itemRender}
                  showTotal={(t) => `总共 ${t} 条`}
                  onChange={(page) => {
                    setCurrent(page);
                  }}
                />
              </>
            )
            : <Empty description="暂无数据" />
        }
      </Col>

    </Row>
  );
};

export default ArticleList;
