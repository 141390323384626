import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '@/store/context';
import { getUrl } from '@/utils';

import './index.less';

/**
 * 计算导航菜单的class
 *
 * @param {{ id: string; name: string; }[]} navPath
 * @param {string} id
 * @returns {string}
 */
const getClass = (navPath: { id: string; name: string; }[], id: string): string => {
  const newNavPath = [...navPath];
  // eslint-disable-next-line array-callback-return
  newNavPath.map((item) => {
    if (item.name === '首页' && item.id === '1') {
      // eslint-disable-next-line no-param-reassign
      item.id = 'baee8061-61b5-45fe-ae3d-b66ab8e9ce87';
    }
  });
  const current = navPath.find((nav) => nav.id === id);
  if (current) {
    // 判断首页时，如果发现路径中还有其他栏目，则不选中首页菜单
    if (current.name === '首页' && navPath.length > 1) {
      return 'nav-item';
    }
    return 'nav-item active';
  }
  return 'nav-item';
};

/**
 * 递归拼接导航栏
 *
 * @param {ChannelType[]} navList 原始数据
 * @param {{id: string; name: string;}[]} navPath 已选
 */
const renderNav = (
  navList: ChannelType[],
  navPath: { id: string; name: string; }[],
) => (
  <ul>
    {
      navList.map((item: ChannelType) => {
        if (item.showStatus === 1 || item.showStatus === 2) {
          if (item.ChannelType?.name === '外部链接') {
            return (
              <li
                key={item.id}
                attr-type={item.ChannelType.name}
              >
                <a href={item.url} target="_black">{item.name}</a>
              </li>
            );
          }
          return (
            <li
              key={item.id}
              attr-id={item.id}
              attr-name={item.name}
              attr-enname={item.enName}
              attr-showstatus={item.showStatus}
              attr-keyword={item.keyWord}
              attr-channeltype={JSON.stringify(item.ChannelType || {})}
              className={getClass(navPath, item.id)}
            >
              <Link
                to={getUrl(item)}
              >
                {item.name}
                {
                  // 半隐藏
                  (item.showStatus === 1 || item.showStatus === 2) && item.children?.length
                    ? <span className="nextNavSpan">&gt;</span>
                    : ''
                }
              </Link>
              {
                // 半隐藏的菜单，在顶部导航中不显示其子菜单
                ((item.showStatus === 1 || item.showStatus === 2) && item.children?.length)
                  ? renderNav(item.children, navPath)
                  : ''
              }
            </li>
          );
        }
        return <></>;
      })
    }
  </ul>
);

const NavList: FC = () => {
  // const [current, setCurrent] = useState<number[]>([1]);
  const { state, dispatch } = useContext(Context);
  const { navList, navPath } = state;
  return (
    <div
      className="nav"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        if (e.target.tagName !== 'A') return; // 只有a标签会触发路由跳转，所以此处同步全局导航路径时要做判断
        if (e.target.parentNode.getAttribute('attr-type') === '外部链接') return; // 外部链接直接跳转，不触发路由
        if (!e.nativeEvent || !e.nativeEvent.path) {window.location.reload()}
        const allPath: HTMLElement[] = e.nativeEvent.path || []; // 事件路径        
        const pathDom = allPath.filter((dom) => dom.className && dom.className.includes('nav-item')); // 过滤
        const path = pathDom.map((dom) => ({
          id: dom.getAttribute('attr-id') || '',
          name: dom.getAttribute('attr-name') || '',
          enName: dom.getAttribute('attr-enname') || '',
          showStatus: parseInt(dom.getAttribute('attr-showstatus') || '', 0),
          keyWord: dom.getAttribute('attr-keyWord') || '',
          ChannelType: JSON.parse(dom.getAttribute('attr-channeltype') || '{}'),
        })).reverse(); // 取得idList
        dispatch && dispatch({ type: 'SET_SELECT', navPath: path });
      }}
    >
      {renderNav(navList, navPath)}
    </div>
  );
};

export default NavList;
