/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState, useContext } from 'react';

import { getCommonSettings } from '@/api';
import Context from '@/store/context';
import './index.less';

import logo from '@images/logo.png';
import { Col, Row, Input } from 'antd';
import NavList from '@components/NavList';
import MobileNavList from '@components/MobileNavList';
import Entrance from './Entrance';

const { Search } = Input;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const bindGloblEvent = (handler: () => void) => {
  // 注意处理事件解绑
  window.document.addEventListener('mousedown', handler);
};

const GlobalHeader: FC = () => {
  const [entranceList, setEntranceList] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any;
    text: string;
    url?: string;
  }[]>([]);
  const { state: { navList }, dispatch } = useContext(Context);
  const [newNvaName, setName] = useState<{
    id: string;
    text: string;
    url?: string;
  }[]>([]);
  useEffect(() => {
    (async () => {
      // 获取右上角入口
      const list = await getCommonSettings();
      const eList = list
        .filter((e: ChannelSettingType) => e.group === 'topright')
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((e: ChannelSettingType) => ({ id: e.id, text: e.title, url: e.link }));
      setEntranceList(eList);
    })();
    // eslint-disable-next-line no-shadow
    const name: {
      id: string;
      text: string;
      url?: string;
    }[] = [];

    // eslint-disable-next-line no-shadow
    const nvaName = (value: ChannelType[]) => {
      // eslint-disable-next-line consistent-return
      value.map((item: ChannelType) => {
        if (item.showStatus === 4) {
          if (item.name === '网安风向标') {
            if (!item.url) {
              return name.push({ text: item.name, id: item.id, url: '/channel/ys-zjlaq' });
            }
          }
          return name.push({ text: item.name, id: item.id, url: item.url });
        }

        if (item.children?.length) {
          nvaName(item.children);
        }
      });
    };
    nvaName(navList);
    setName(name);
  }, [navList]);
  return (
    <>
      <Row
        className="center"
      >
        <Col className="left" flex="450px">
          <a id="logo" href="/">
            <img src={logo} alt="logo" />
          </a>
        </Col>
        <Col className="right" flex="auto">
          <Row className="right-top" justify="end">
            <Col><Entrance entranceList={entranceList} nvaName={newNvaName} /></Col>
            <Col
              onMouseOver={(e) => {
                const searchDom = e.currentTarget.querySelector('.search');
                searchDom && searchDom.classList.add('active');
              }}
              onMouseOut={(e) => {
                const judgeFocus = e.currentTarget.querySelector('input') === document.activeElement;
                const searchDom = e.currentTarget.querySelector('.search');
                if (!judgeFocus) {
                  searchDom && searchDom.classList.remove('active');
                }
              }}
            >
              <Search
                className="search"
                placeholder="输入关键词搜索"
                onSearch={(value) => {
                  // eslint-disable-next-line no-console
                  console.log(value);
                  const searchDom = document.body.querySelector('.search.active');
                  searchDom && searchDom.classList.remove('active');
                }}
                size="small"
                enterButton
              />
            </Col>
          </Row>
          <NavList />
        </Col>
        <Col className="mobileRight" flex="40px">
          <MobileNavList />
        </Col>
      </Row>
    </>
  );
};

export default GlobalHeader;
