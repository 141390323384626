/** !
 * @description: 产品推荐
 * @author: zpl
 * @Date: 2020-09-23 14:47:40
 * @LastEditTime: 2020-09-23 14:47:45
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Row, Col, Image } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImg from '@images/bg02.png';
import './index.less';
import { sourceUrl } from '@/constant';

const getList = (list: ChannelSettingType[]): PicListType[] => {
  const newList = list
    .filter((setting) => setting.group === '厂商&产品推荐')
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map((setting) => ({
      id: setting.id,
      text: setting.title,
      url: setting.link,
      img: setting.pic.startsWith('http') ? setting.pic : sourceUrl + setting.pic,
    }));
  const n = 6 - newList.length;
  for (let i = 0; i < n; i += 1) {
    newList.push({
      id: i,
      text: '',
      url: '#',
      img: bgImg,
    });
  }
  return newList;
};

interface PropsType {
  settings: ChannelSettingType[]
}

const Products: FC<PropsType> = ({ settings = [] }: PropsType) => {
  const sliderSettings = {
    dots: false, // 底部的点
    infinite: true,
    slidesToShow: 3, // 在一帧中显示多少张幻灯片
    slidesToScroll: 1, // 一次滚动几张幻灯片
    autoplay: true, // 自动播放
    speed: 2000, // 播放的速度
    autoplaySpeed: 2000, // 每次自动滚动之间的延迟（以毫秒为单位）
    cssEase: 'linear',
  };
  const currentList = getList(settings);
  return (
    <div className="productsCon">
      <Row className="productsTitle">
        <Col flex="auto">
          厂商&amp;产品推荐
        </Col>
        <Col flex="40px" className="more">
          <a href="/channel/csml">更多</a>
        </Col>
      </Row>
      <div className="productsPicListCon">
        <Row gutter={16} className="productsPicList">
          {
            currentList.map((item) => (
              <Col key={item.id} span={4}>
                <a href={item.url}>
                  <Image
                    src={`${item.img.startsWith('http') ? item.img : (sourceUrl + item.img)}`}
                    fallback={bgImg}
                    preview={false}
                  />
                </a>
              </Col>
            ))
          }
        </Row>

        <div className="porductsSlider">
          <Slider {...sliderSettings}>
            {
              currentList.map((item) => (
                <div key={item.id}>
                  <a href={item.url}>
                    <Image
                      src={`${item.img.startsWith('http') ? item.img : (sourceUrl + item.img)}`}
                      fallback={bgImg}
                      preview={false}
                    />
                  </a>
                </div>
              ))
            }
          </Slider>
        </div>

      </div>
    </div>
  );
};

export default Products;
