/** !
 * @description: 普通文章列表
 * @author: zpl
 * @Date: 2020-09-24 10:20:08
 * @LastEditTime: 2020-09-24 10:20:14
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Empty, List, Pagination, Row } from 'antd';

import Context from '@/store/context';
import { getPubList } from '@/api';
import { getSettingsForChannel } from '@/utils';
import SecondNav from '@/components/SecondNav';
import QuickEntry from '@/components/QuickEntry';

import './index.less';

const itemRender = (current: number, type: string, originalElement: React.ReactElement) => {
  if (type === 'prev') {
    return <a>上一页</a>;
  }
  if (type === 'next') {
    return <a>下一页</a>;
  }
  return originalElement;
};

interface PropType {
  channelId: string;
  channelName: string;
  enName: string;
}
const ArticleList: FC<PropType> = ({ channelId, channelName, enName }: PropType) => {
  const { state: { navList, commonSettings } } = useContext(Context);
  const [current, setCurrent] = useState(1);
  const pageSize = 20;
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<ListItmeType[]>([]);
  const [settings, setSettings] = useState<ChannelSettingType[]>([]);

  useEffect(() => {
    const currentSettings = getSettingsForChannel(commonSettings, navList, enName);
    setSettings(currentSettings);
  }, [commonSettings, navList, enName]);

  useEffect(() => {
    (async () => {
      const data = await getPubList(channelId, current, pageSize);
      setTotal(data.total);
      setList(data.list);
      // 翻页的时候置顶
      document.documentElement.scrollTop = 0;
    })();
  }, [channelId, current, pageSize]);

  return (
    <Row gutter={30}>
      <div className="buju">
        <Col flex="320px">
          <SecondNav />
          <div className="left-bottom">
            <QuickEntry settings={settings} column={2} />
          </div>
        </Col>
      </div>

      <div className="bujus">
        <Col flex="auto">
          <div className="topTitle">
            <span>{channelName}</span>
          </div>
          {
            list.length
              ? (
                <>
                  <List
                    className="aritcleListCon"
                    size="small"
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <a href={`/channel/${enName}#${item.id}`} target="_blank" rel="noreferrer">{item.title}</a>
                          }
                        />
                        <div className="date">{item.conDate.split('T')[0].split(' ')[0]}</div>
                      </List.Item>
                    )}
                  />
                  <Pagination
                    size="small"
                    total={total}
                    current={current}
                    pageSize={pageSize}
                    showSizeChanger={false}
                    itemRender={itemRender}
                    showTotal={(t) => `总共 ${t} 条`}
                    onChange={(page) => {
                      setCurrent(page);
                    }}
                  />
                </>
              )
              : <Empty description="暂无数据" />
          }
        </Col>
      </div>

    </Row>
  );
};

export default ArticleList;
