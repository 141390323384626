/** !
 * @description: 首页
 * @author: zpl
 * @Date: 2020-09-27 22:37:46
 * @LastEditTime: 2020-10-07 15:14:43
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import Context from '@/store/context';
import QuickEntry from '@components/QuickEntry';
import { getHomePageData } from '@/api';
import { getSettingsForChannel, isMobile } from '@/utils';
import { indexNav } from '@/constant';
import Banner from './components/Banner';
import Recom from './components/Recom/index';
import AssociationNews from './components/AssociationNews';
import NewsTab from './components/NewsTab';
import Products from './components/Products';
import './index.less';
import TrainingEntrance from './components/TrainingEntrance';

const HomePage: FC = () => {
  const { state: { navPath, navList, commonSettings }, dispatch } = useContext(Context);
  const [settings, setSettings] = useState<ChannelSettingType[]>([]);
  // const [quickEntryListtings, setQuickEntryList] = useState<ChannelSettingType[]>([]);
  const [pageData, setPageData] = useState<HomePageType>({
    headList: [],
    associationNewsList: [],
    newsTabList: [],
    trainingEntranceList: [],
    productsList: [],
  });
  useEffect(() => {
    (async () => {
      const currentSettings = await getHomePageData();
      // const quickEntryList = await getSettingForChannel(1);
      setPageData(currentSettings);
      // setQuickEntryList(quickEntryList);
    })();
  }, []);

  useEffect(() => {
    // 获取装饰配置
    const currentSettings = getSettingsForChannel(commonSettings, navList, 'index');
    setSettings(currentSettings);
    if (dispatch && navPath.length > 1) {
      dispatch({
        type: 'SET_SELECT',
        navPath: [indexNav],
      });
    }
  }, [commonSettings, dispatch, navList, navPath]);

  return (
    <div className="indexCon">
      <Banner settings={settings} />

      {/* 第一行 */}
      <div className="row">
        <Row gutter={16}>
          <Col xs={24} sm={13} order={isMobile ? 2 : 0}>
            <Recom recomList={pageData.headList} />
          </Col>
          <Col xs={24} sm={11}>
            <AssociationNews dataList={pageData.associationNewsList} />
          </Col>
        </Row>
      </div>
      {/* 第二行 */}
      <div className="row">
        <Row gutter={16}>
          {
            isMobile && (
              <Col xs={24} sm={12} className="mobileShow">
                <QuickEntry settings={settings} />
              </Col>
            )
          }
          <Col xs={24} sm={12}>
            <NewsTab dataList={pageData.newsTabList} />
          </Col>
          <Col xs={24} sm={12}>
            <TrainingEntrance dataList={pageData.trainingEntranceList} />
            {!isMobile && <QuickEntry settings={settings} />}
          </Col>
        </Row>
      </div>
      {/* 第三行 */}
      <div className="row">
        <Products settings={settings} />
      </div>
    </div>
  );
};

export default HomePage;
