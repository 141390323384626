/* eslint-disable consistent-return */
/** !
 * @description: 二级导航
 * @author: zpl
 * @Date: 2020-09-24 14:57:06
 * @LastEditTime: 2020-09-24 14:57:15
 * @LastEditors: zpl
 */
import React, { FC, useContext } from 'react';
import { Menu } from 'antd';

import { findChildrenNav, getNavPath } from '@/utils';
import Context from '@/store/context';
import { indexNav } from '@/constant';

import './index.less';

const { SubMenu } = Menu;
// eslint-disable-next-line array-callback-return
const renderMenu = (children: ChannelType[]) => children.map((item) => {
  if (item.children && item.children.length) {
    return (
      <SubMenu key={item.enName} title={item.name}>
        {
          renderMenu(item.children)
        }
      </SubMenu>
    );
  }
  if (item.showStatus === 1 || item.showStatus === 3) {
    return <Menu.Item key={item.enName}>{item.name}</Menu.Item>;
  }
});

const SecendNav: FC = () => {
  const { state: { navList, navPath }, dispatch } = useContext(Context);
  const currentNav = navPath.length
    ? navPath[navPath.length - 1]
    : indexNav;
  const parent = navPath.length > 1 ? navPath[1] : indexNav;
  const currentChildren = findChildrenNav(navList, parent.id);
  const [openKeys, setOpenKeys] = React.useState<string[]>(navPath.map((nav) => nav.enName));
  const rootSubmenuKeys = currentChildren.map((nav) => nav.enName);
  const onOpenChange = (keys: React.Key[] | {
    key: React.Key;
    item: React.ReactInstance;
    trigger: string;
    open: boolean;
  }) => {
    const latestOpenKey = (keys as string[]).find((key) => openKeys.indexOf(key) === -1) || '';
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys as string[]);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <div className="subMenuTitle">{parent.name}</div>
      <div className="secondNavCon">
        <Menu
          openKeys={openKeys}
          // defaultOpenKeys={navPath.map((nav) => nav.enName)}
          selectedKeys={[`${currentNav.enName}`]}
          mode="inline"
          onOpenChange={onOpenChange}
          onClick={({ key }) => {
            const newPath = getNavPath(navList, `${key}`);
            if (newPath.length && newPath[0].ChannelType?.name === '外部链接') {
              window.open(newPath[0].url);
              return;
            }
            window.location.hash = '';
            newPath.push(indexNav);
            dispatch && dispatch({ type: 'SET_SELECT', navPath: newPath.reverse() });
          }}
        >
          {
            renderMenu(currentChildren)
          }
        </Menu>
      </div>
    </>
  );
};

export default SecendNav;
