import React, { FC, useContext } from 'react';
import { BackTop, Spin } from 'antd';

import Context from '@/store/context';
import { findChildrenNav } from '@/utils';
import { LoadingOutlined } from '@ant-design/icons';
import ArticleList from './template/ArticleList';
import ArticlePicList from './template/ArticlePicList';
import CardList from './template/CardList';
import Content from './template/Content';

import './index.less';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const renderPage = (nav: ChannelType) => {
  const oldEnName = window.location.pathname.split('/')[2];
  const contentId = window.location.hash;
  if (contentId && oldEnName === nav.enName) {
    return <Content />;
  }
  switch (nav.ChannelType?.name) {
    case '文章列表':
      return (
        <ArticleList channelId={nav.id} channelName={nav.name} enName={nav.enName} />
      );
    case '图文列表':
    case '安全培训':
      if (nav.keyWord?.includes('纯logo')) {
        return (
          <CardList channelId={nav.id} channelName={nav.name} enName={nav.enName} />
        );
      }
      return (
        <ArticlePicList channelId={nav.id} channelName={nav.name} enName={nav.enName} />
      );
    case '单篇文章':
      return <Content />;
    default:
      return (<Spin style={{ display: 'block', margin: '0 auto', padding: '130px 0' }} indicator={antIcon} />);
      // return (
      //   <div style={{ padding: '20px', textAlign: 'center' }}>
      //     <Empty description="这个页面还没处理" />
      //     <div>
      //       {JSON.stringify(nav)}
      //     </div>
      //   </div>
      // );
  }
};

const OtherPage: FC = () => {
  // layout处理了主菜单和导航
  const {
    state: { navList, navPath },
    dispatch,
  } = useContext(Context);
  const nav = navPath[navPath.length - 1];
  // const [nav, setNav] = useState(navPath[navPath.length - 1]);
  // useEffect(() => {
  //   setNav(navPath[navPath.length - 1]);
  // }, [navPath]);

  if (nav && nav.ChannelType?.name === '主页') {
    const childrenList = findChildrenNav(navList, navPath[navPath.length - 1].id);

    if (childrenList.length) {
      const newList = [...navPath];
      newList.push({
        id: childrenList[0].id,
        name: childrenList[0].name,
        enName: childrenList[0].enName,
        keyWord: childrenList[0].keyWord,
        showStatus: childrenList[0].showStatus,
        ChannelTypeId: childrenList[0].ChannelTypeId,
        ChannelType: childrenList[0].ChannelType,
      });
      dispatch && dispatch({ type: 'SET_SELECT', navPath: newList });
    }
  }
  return (
    <div className="pageCon">
      {
        nav
          ? renderPage(nav)
          : <></>
        }
      <BackTop>
        <div className="top">
          &nbsp;
        </div>
      </BackTop>
    </div>
  );
};

export default OtherPage;
