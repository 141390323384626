/** !
 * @description: 申请加入单位会员
 * @author: zpl
 * @Date: 2020-11-07 11:54:32
 * @LastEditTime: 2020-11-07 11:55:54
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Button, Form, Input, message } from 'antd';
import { companySub } from '@/api';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const onFinish = async (values: {
  address:string,
  contacts:string,
  contactsMobile:string,
  corporateName:string,
  email:string,
  intro:string,
  tel:string,
  website:string,
  zipCode:string
}) => {
  const res = await companySub(values);
  if (res === '') {
    message.info('申请提交成功，审核人员将会您联系');
  } else if (res === 'Internal Server Error') {
    message.error('申请提交失败，请稍后再试');
  } else {
    message.warn(res);
  }
};
const JoinUsForCompany: FC = () => (
  <Form {...formItemLayout} onFinish={onFinish}>
    <Form.Item
      name="corporateName"
      label="单位名称"
      rules={[
        {
          required: true,
          message: '请填写单位名称',
        },
      ]}
    >
      <Input maxLength={50} />
    </Form.Item>
    <Form.Item
      name="tel"
      label="单位电话"
      rules={[
        {
          pattern: new RegExp(/^(0\d{2,3})?-?(\d{7,8})$/),
          message: '电话格式有误',
        },
        {
          required: true,
          message: '请填写单位电话',
        },
      ]}
    >
      <Input placeholder="例如： 029-88888888" />
    </Form.Item>
    <Form.Item
      name="email"
      label="联系邮箱"
      rules={[
        {
          type: 'email',
          message: '邮箱格式有误',
        },
        {
          required: true,
          message: '请填写联系邮箱',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="address"
      label="联系地址"
      rules={[
        {
          required: true,
          message: '请填写联系地址',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="zipCode"
      label="单位邮编"
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="website"
      label="单位网站"
      rules={[
        {
          type: 'url',
          message: '网站格式有误',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="contacts"
      label="联系人"
      rules={[
        {
          required: true,
          message: '请填写联系人',
        },
      ]}
    >
      <Input maxLength={10} />
    </Form.Item>
    <Form.Item
      name="contactsMobile"
      label="手机号"
      rules={[
        {
          pattern: new RegExp(
            /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
          ),
          message: '手机号码格式有误',
        },
        {
          required: true,
          message: '请填写手机号',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="intro"
      label="单位简介"
      rules={[
        {
          required: true,
          message: '请填写单位简介',
        },
      ]}
    >
      <Input.TextArea autoSize={{ minRows: 5 }} showCount maxLength={200} />
    </Form.Item>
    <Form.Item {...tailLayout}>
      <Button style={{ width: 120 }} type="primary" htmlType="submit" shape="round">
        提交
      </Button>
    </Form.Item>
  </Form>
);

export default JoinUsForCompany;
