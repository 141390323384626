import axios from 'axios';

import { convertChannelsToTree } from './utils';
// import HomePageData from './assets/testdata/index.json';

/**
 * 获取导航菜单
 *
 * @returns {Promise<ChannelType[]>}
 */
export const getNav = async (): Promise<ChannelType[]> => {
  try {
    const res = await axios.get('/api/menu');
    if (res.status === 200 && res.statusText === 'OK') {
      const navTree: ChannelType[] = [];
      convertChannelsToTree(res.data.data, navTree, null);
      return navTree;
    }
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

/**
 * 获取首页数据
 *
 * @return {*}  {Promise<HomePageType>}
 */
export const getHomePageData = async (): Promise<HomePageType> => {
  const defaultData: HomePageType = {
    headList: [],
    associationNewsList: [],
    newsTabList: [],
    trainingEntranceList: [],
    productsList: [],
  };
  try {
    const res = await axios.get('/api/getHomePageData');
    if (res.status === 200 && res.data.status === 'ok') {
      return res.data.data;
    }
    return defaultData;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return defaultData;
  }
  // return new Promise((resolve, reject) => {
  //   resolve(
  //     HomePageData,
  //   );
  // });
};

/**
 * 获取全局公共配置
 *
 * @returns
 */
export const getCommonSettings = async (): Promise<ChannelSettingType[]> => {
  try {
    const res = await axios({
      url: '/api/getCommonSettings',
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return res.data.data;
    }
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

/**
 * 获取头条文章列表
 *
 * @returns {ListItmeType[]}
 */
export const getHeadList = async (): Promise<ListItmeType[]> => {
  try {
    const res = await axios.get('/api/headList');
    if (res.status === 200 && res.data.status === 'ok') {
      const { list } = res.data.data;
      return list;
    }
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

/**
 * 获取推荐文章列表
 *
 * @returns {Promise<ListItmeType[]>}
 */
export const getRecomList = async (): Promise<ListItmeType[]> => {
  try {
    const res = await axios.get('/api/recomList');
    if (res.status === 200 && res.data.status === 'ok') {
      const { list } = res.data.data;
      return list;
    }
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

/**
 * 获取文章列表
 *
 * @param {number} channelId 栏目ID
 * @param {number} current 页数
 * @param {number} pageSize 每页条数
 * @returns {total: number; list: ListItmeType[]}
 */
export const getPubList = async (
  channelId: string, current: number, pageSize: number,
): Promise<{
  total: number; list: ListItmeType[]
}> => {
  try {
    const res = await axios.post('/api/getPubList', {
      channelId,
      current,
      pageSize,
      orderName: 'conDate',
      orderValue: 'desc',
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return res.data.data;
    }
    return {
      total: 0,
      list: [],
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      total: 0,
      list: [],
    };
  }
};

/**
 * 按ID获取文章内容
 *
 * @param {string} id
 * @returns {(Promise<ContentType | null>)}
 */
export const getContent = async (id: string): Promise<ContentType | null> => {
  try {
    const res = await axios.get(`/api/content/${id}`);
    if (res.status === 200 && res.data.status === 'ok') {
      return res.data.data;
    }
    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

/**
 * 培训报名
 *
 * @param {string} name
 * @param {string} mobile
 * @param {string} email
 * @param {string} comp
 * @param {string} TrainingId
 * @return {*}  {Promise<string>}
 */
export const trainingReg = async (
  name: string,
  mobile: string,
  email: string,
  comp: string,
  TrainingId: string,
): Promise<string> => {
  try {
    const res = await axios.put('/api/training/reg', {
      name, mobile, email, comp, TrainingId,
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return '';
    }
    return res.data.message;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return 'Internal Server Error';
  }
};

/**
 * 申请加入单位会员
 *
 * @param {string} address
 * @param {string} contacts
 * @param {string} contactsMobile
 * @param {string} corporateName
 * @param {string} email
 * @param {string} intro
 * @param {string} tel
 * @param {string} website
 * @param {string} zipCode
 *
 */
export const companySub = async (
  values:{
    address:string,
    contacts:string,
    contactsMobile:string,
    corporateName:string,
    email:string,
    intro:string,
    tel:string,
    website:string,
    zipCode:string
  },

): Promise<string> => {
  try {
    const { address, contacts, contactsMobile, corporateName, email, intro, tel, website, zipCode } = values;
    const res = await axios.put('/api/memberCompany/reg', {
      address,
      contacts,
      contactsMobile,
      corporateName,
      email,
      intro,
      tel,
      website,
      zipCode,
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return '';
    }
    return res.data.message;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return 'Internal Server Error';
  }
};

/**
 * 申请加入个人会员
 *
 * @param {{
 *     name:string,
 *     sex:number,
 *     idNumber:string,
 *     mobile:string,
 *     email:string,
 *     profession:string,
 *     website:string,
 *     intro:string,
 *   }} values
 * @return {*}  {Promise<string>}
 */
export const indivicSub = async (
  values:{
    name:string,
    sex:number,
    idNumber:string,
    mobile:string,
    email:string,
    profession:string,
    website:string,
    intro:string,
  },

): Promise<string> => {
  try {
    const { name, sex, idNumber, mobile, email, profession, website, intro } = values;
    const res = await axios.put('/api/memberIndivic/reg', {
      name,
      sex,
      idNumber,
      mobile,
      email,
      profession,
      website,
      intro,
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return '';
    }
    return res.data.message;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return 'Internal Server Error';
  }
};

/**
 * 安全服务
 *
 * @param {string} corporateName 公司名称
 * @param {string} tel 座机
 * @param {string} email 邮箱
 * @param {string} address 地址
 * @param {string} zipCode 邮编
 * @param {string} website 企业网站
 * @param {string} contacts 联系人
 * @param {string} contactsMobile 联系人手机
 * @param {string} demandType 需求类型
 * @param {string} requestDesc 需求描述
 * @return {*}  {Promise<string>}
 */
export const servicesReg = async (
  corporateName: string,
  tel: string,
  email: string,
  address: string,
  zipCode: string,
  website: string,
  contacts: string,
  contactsMobile: string,
  demandType: string,
  requestDesc: string,
): Promise<string> => {
  try {
    const res = await axios.put('/api/putServiceRequest', {
      corporateName, tel, email, address, zipCode, website, contacts, contactsMobile, demandType, requestDesc,
    });
    if (res.status === 200 && res.data.status === 'ok') {
      return '';
    }
    return res.data.message;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return 'Internal Server Error';
  }
};

// try {
// const res = await axios({
//   url: '/oldapi/getSettingForChannel',
//   method: 'post',
//   data: {
//     cId,
//   },
// });
// if (res.status === 200 && res.data.status === 'ok') {
//   return res.data.settings;
// }
// return data;
// } catch (e) {
// eslint-disable-next-line no-console
// console.error(e);
// return [];
// }
