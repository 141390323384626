/* eslint-disable max-len */
/** !
 * @description: 文章内容
 * @author: zpl
 * @Date: 2020-09-25 14:18:54
 * @LastEditTime: 2020-09-25 14:19:04
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Interweave from 'interweave';

import SecondNav from '@/components/SecondNav';
import QuickEntry from '@/components/QuickEntry';
import { getContent, getPubList } from '@/api';
import { getSettingsForChannel } from '@/utils';
import Context from '@/store/context';
import { indexNav } from '@/constant';
import moment from 'moment';

import './index.less';
import JoinUsForCompany from './components/JoinUsForCompany';
import JoinUsForIndivic from './components/JoinUsForIndivic';
import ProvideServices from './components/ProvideServices';
import ProvideTraining from './components/ProvideTraining';

const ContentPage: FC = () => {
  const { state: { navPath, navList, commonSettings } } = useContext(Context);
  const currentNav = navPath.length
    ? navPath[navPath.length - 1]
    : indexNav;
  const [settings, setSettings] = useState<ChannelSettingType[]>([]);
  const [content, setContent] = useState<ContentType | null>(null);
  useEffect(() => {
    // 获取装饰配置
    const currentSettings = getSettingsForChannel(commonSettings, navList, currentNav.enName);
    setSettings(currentSettings);

    // 获取文章内容
    (async () => {
      let currentId = window.location.hash.substring(1);
      if (!currentId) {
        const res = await getPubList(currentNav.id, 1, 1);
        if (res.total) {
          currentId = res.list[0].id;
        }
      }
      if (currentId) {
        const href = `/channel/${currentNav.enName}#${currentId}`;
        window.location.replace(href);
        const currentContent = await getContent(currentId);
        setContent(currentContent);
      }
    })();
  }, [commonSettings, currentNav, navList]);
  const trainingSettings = content ? content.ArticleExtensions.find((extension) => extension.title === '培训') : null;
  return (
    <div className="articleCon">
      <Row gutter={30}>
        <div className="buju">
          <Col flex="320px">
            <SecondNav />
            <div className="left-bottom">
              <QuickEntry settings={settings} column={2} />
            </div>
          </Col>
        </div>

        <div className="bujus">
          <Col flex="auto">
            <div className="topTitle">
              <span>{currentNav.name}</span>
            </div>
            <div className="contentCon">
              <div className="content-title">{content ? content.title : ''}</div>
              <div className="content-time">
                {`时间：${content ? moment(content.conDate).format('YYYY-MM-DD HH:mm') : ''}`}
                <span style={{ marginLeft: 20 }}>{` 来源：${content?.source}`}</span>
              </div>
              <div className="content-mainCon">
                <Interweave content={content ? content.mainCon : ''} />
              </div>
              {content && content.title === '单位会员申请' && <JoinUsForCompany />}
              {content && content.title === '个人会员申请' && <JoinUsForIndivic />}
              {currentNav && currentNav.keyWord?.includes('服务') && <ProvideServices typeStr={content ? content.title : ''} />}
              {trainingSettings && <ProvideTraining TrainingId={trainingSettings.info} />}
            </div>
          </Col>
        </div>

      </Row>
    </div>
  );
};

export default ContentPage;
