/*
 * @description: 全局状态共享
 * @author: zpl
 * @Date: 2020-09-24 11:57:39
 * @LastEditTime: 2021-01-18 12:26:47
 * @LastEditors: zpl
 */

import { createContext } from 'react';

export default createContext<{ state: CommonStateType; dispatch: React.Dispatch<CommonReaducerType> | null; }>({
  state: {
    navList: [],
    navPath: [],
    commonSettings: [],
  },
  dispatch: null,
});
