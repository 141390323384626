import { indexNav } from './constant';

/**
 * 当前是否是移动端，统一维护判断方法
 */
export const isMobile = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);

/**
 * 转换栏目接口返回数据为组件需要的树型数据结构
 *
 * @param {*} list 原始数据
 * @param {*} channels 目标数据
 * @param {*} parentId 支持递归需要知道挂载到哪个父id上
 */
export const convertChannelsToTree = (
  list: ChannelType[],
  channels: ChannelType[],
  parentId: string | null,
): void => {
  for (let i = list.length - 1; i >= 0; i -= 1) {
    const channel = list[i];
    if (channel.parentId === parentId && channel.showStatus !== 0) {
      channels.push({
        ...channel,
        children: [],
      });
      list.splice(i, 1);
    }
  }
  if (list.length) {
    channels.forEach((channel: ChannelType) => {
      convertChannelsToTree(list, channel.children || [], channel.id);
    });
  }
};

/**
 * 从栏目树中找到指定id的节点
 *
 * @param {ChannelType[]} list
 * @param {string} id
 * @returns {(ChannelType | null)}
 */
let r: ChannelType | null = null;
export const findChannelByTree = (list: ChannelType[], id: string): ChannelType | null => {
  const f = list.find((item) => {
    if (item.id === id) {
      return true;
    }
    if (item.children && item.children.length) {
      const tempChild = findChannelByTree(item.children, id);
      r = tempChild || r;
    }
    return false;
  });
  return f || r;
};

/**
 * 根据英文名称获取导航路径
 *
 * @param {ChannelType[]} navList
 * @param {string} enName
 * @returns {ChannelType[]}
 */
export const getNavPath = (
  navList: ChannelType[], enName: string,
): ChannelType[] => {
  const navPath: ChannelType[] = [];
  const pathNav = navList.find((nav) => {
    if (nav.enName === enName) {
      return true;
    }
    if (nav.children && nav.children.length) {
      const subPath = getNavPath(nav.children, enName);
      if (subPath.length) {
        subPath.forEach((p) => {
          navPath.push(p);
        });
        return true;
      }
      return false;
    }
    return false;
  });
  pathNav && navPath.push({
    id: pathNav.id,
    name: pathNav.name,
    enName: pathNav.enName || '',
    keyWord: pathNav.keyWord || '',
    url: pathNav.url || '',
    settingExtend: pathNav.settingExtend,
    ChannelSettings: pathNav.ChannelSettings || [],
    showStatus: pathNav.showStatus,
    ChannelTypeId: pathNav.ChannelTypeId,
    ChannelType: pathNav.ChannelType,
  });
  return navPath;
};

/**
 * 找到栏目子节点
 *
 * @param {ChannelType[]} navList
 * @param {string} id
 * @returns {{
 *   id: string; name: string; enName: string; channelType: string;
 * }[]}
 */
export const findChildrenNav = (
  navList: ChannelType[],
  id: string,
): ChannelType[] => {
  const node = findChannelByTree(navList, id);
  if (node && node.children && node.children.length) {
    return node.children;
  }
  return [];
};

/**
 * 获取导航对应的URL，要处理一些特殊情况
 *
 * @param {ChannelType} nav
 * @returns {string}
 */
export const getUrl = (nav: ChannelType): string => {
  if (nav.enName === 'index') {
    return '/';
  }
  if (nav.ChannelType?.name === '主页' && nav.children && nav.children.length) {
    return getUrl(nav.children[0]);
  }
  return `/channel/${nav.enName}`;
};

/**
 * 按栏目英文名称获取配置
 *
 * @param {ChannelSettingType[]} commonSettings
 * @param {ChannelType[]} navList
 * @param {string} enName
 * @returns {ChannelSettingType[]}
 */
export const getSettingsForChannel = (
  commonSettings: ChannelSettingType[] = [],
  navList: ChannelType[] = [],
  enName: string,
): ChannelSettingType[] => {
  const settings: ChannelSettingType[] = [];
  const navPath = getNavPath(navList, enName);
  const currentNav = navPath[0];
  if (currentNav) {
    if (currentNav.settingExtend) {
      for (let i = 0; i < navPath.length; i += 1) {
        const currentSettings = navPath[i].ChannelSettings || [];
        settings.push(...currentSettings);
        if (!navPath[i].settingExtend) { break; }
      }
      const rootNav = navPath.length ? navPath[navPath.length - 1] : { settingExtend: 0 };
      if (rootNav.settingExtend) {
        settings.push(...commonSettings);
      }
      return settings;
    }
    return currentNav.ChannelSettings || [];
  }
  return [];
};
