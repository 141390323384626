/** !
 * @description: 推荐轮播
 * @author: zpl
 * @Date: 2020-09-23 15:38:11
 * @LastEditTime: 2020-09-23 15:38:38
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Carousel, Spin } from 'antd';
import './index.less';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface PropsType {
  recomList: ListItmeType[],
}
const Recom: FC<PropsType> = ({ recomList = [] }:PropsType) => {
  if (recomList.length) {
    return (
      <>
        <Carousel
          autoplay
          dots={false}
          className="recomCon"
        >
          {recomList.map((item) => (
            <a
              key={item.id}
              href={`/channel/${item.Channels.length ? item.Channels[0].enName : 'index'}#${item.id}`}
            >
              <div className="itemBack">
                <div className="info">
                  <div className="title">{item.title}</div>
                  <div className="date">{item.conDate.split('T')[0].split(' ')[0]}</div>
                </div>
              </div>
              <img
                src={item.thumbnail}
                alt={item.title}
                className="itemImg"
              />
            </a>
          ))}
        </Carousel>
      </>
    );
  }
  return <Spin style={{ display: 'block', margin: '0 auto', padding: '130px 0' }} indicator={antIcon} />;
};

export default Recom;
