/** !
 * @description: 图文列表
 * @author: zpl
 * @Date: 2020-09-24 10:20:08
 * @LastEditTime: 2020-09-24 10:20:14
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Row, Col, Empty, Pagination, Card, Image } from 'antd';

import { getPubList } from '@/api';
import { getSettingsForChannel } from '@/utils';
import QuickEntry from '@components/QuickEntry';
import Context from '@/store/context';
import { defImg, indexNav } from '@/constant';
import SecondNav from './components/SecondNav';
import ManufacturerEntryForm from './components/EntryForm';

// import productsDate from '@assets/testdata/products.json';
import './index.less';

const { Meta } = Card;

const itemRender = (current: number, type: string, originalElement: React.ReactElement) => {
  if (type === 'prev') {
    return <a>上一页</a>;
  }
  if (type === 'next') {
    return <a>下一页</a>;
  }
  return originalElement;
};

interface PropType {
  channelId: string;
  channelName: string;
  enName: string;
}
const CardList: FC<PropType> = ({ channelId, channelName, enName }: PropType) => {
  // eslint-disable-next-line no-console
  console.debug(channelId);
  const { state: { navPath, navList, commonSettings } } = useContext(Context);
  const [current, setCurrent] = useState(1);
  const pageSize = 20;
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<ListItmeType[]>([]);
  const [settings, setSettings] = useState<ChannelSettingType[]>([]);
  const [isFirmShow, setIsFirmShow] = useState(false);
  const [isProductShow, setIsProductShow] = useState(false);

  useEffect(() => {
    // 获取装饰配置
    const currentSettings = getSettingsForChannel(commonSettings, navList, enName);
    setSettings(currentSettings);
  }, [navList, commonSettings, enName]);

  useEffect(() => {
    (async () => {
      const currentNav = navPath.length
        ? navPath[navPath.length - 1]
        : indexNav;
      const data = await getPubList(currentNav.id, current, pageSize);
      setTotal(data.total);
      setList(data.list);
    })();
  }, [navPath, current, pageSize]);

  const isFromShow = () => {
    if (isFirmShow) {
      setIsFirmShow(false);
    } else if (isProductShow) {
      setIsProductShow(false);
    }
  };
  return (
    <Row gutter={30}>
      <div className="buju">
        <Col>
          <div className="left-bottom" style={{ marginTop: '30px' }}>
            <QuickEntry settings={settings} column={2} />
          </div>
          <div className="apply">
            <div className="span">入驻申请</div>
            <div className="minglu">
              <a
                className="changshang"
                onClick={() => {
                  setIsFirmShow(true);
                  setIsProductShow(false);
                }}
              >
                厂商名录
              </a>
              <a
                className="chanping"
                onClick={() => {
                  setIsFirmShow(false);
                  setIsProductShow(true);
                }}
              >
                产品名录
              </a>
            </div>
          </div>
        </Col>
      </div>
      <div className="bujus">
        <Col>
          <div className="topTitle">
            <span>{isFirmShow ? '厂商入驻' : isProductShow ? '产品入驻' : channelName}</span>
          </div>
          {
            // eslint-disable-next-line max-len
            isFirmShow || isProductShow ? <ManufacturerEntryForm isFirmShow={isFirmShow} isProductShow={isProductShow} isFromShow={isFromShow} />
              : (
                <div>
                  <SecondNav />
                  <div className="cardListCon">
                    {
                      list.length
                        ? (
                          <>
                            <div className="listCon">
                              <Row gutter={20}>
                                {
                                  list.map((item) => (
                                    <Col key={item.id} xs={24} sm={6}>
                                      <a href={`/channel/${enName}#${item.id}`} target="_blank" rel="noreferrer">
                                        <Card>
                                          <Meta
                                            avatar={(
                                              <div className="thumbnail">
                                                <Image
                                                  src={item.thumbnail}
                                                  fallback={defImg}
                                                  preview={false}
                                                />
                                              </div>
                                            )}
                                            title={item.title}
                                            description={item.summary}
                                          />
                                        </Card>
                                      </a>
                                    </Col>
                                  ))
                                }
                              </Row>
                            </div>
                            <Pagination
                              size="small"
                              total={total}
                              current={current}
                              pageSize={pageSize}
                              showSizeChanger={false}
                              itemRender={itemRender}
                              showTotal={(t) => `总共 ${t} 条`}
                              onChange={(page) => {
                                setCurrent(page);
                              }}
                            />
                          </>
                        )
                        : <Empty description="暂无数据" />
                    }
                  </div>
                </div>
              )

          }
          <div className="show-on-moblie">
            <Col>
              <div className="apply">
                <div className="span">入驻申请</div>
                <div className="minglu">
                  <a
                    className="changshang"
                    onClick={() => {
                      setIsFirmShow(true);
                      setIsProductShow(false);
                      window.scrollTo(window.pageXOffset, 0);
                    }}
                  >
                    厂商名录
                  </a>
                  <a
                    className="chanping"
                    onClick={() => {
                      setIsFirmShow(false);
                      setIsProductShow(true);
                      window.scrollTo(window.pageXOffset, 0);
                    }}
                  >
                    产品名录
                  </a>
                </div>
              </div>
            </Col>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default CardList;
