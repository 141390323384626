/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { FC, useContext, useEffect, useState } from 'react';
import Context from '@/store/context';
import axios from 'axios';

import { Form, Input, Button, Select, message } from 'antd';

const { Option } = Select;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type FormProps = {
  type: string;
  corporateName: string;
  contacts: string;
  contactsMobile: string;
  tel: string;
  email: string;
  website?: string;
  address?: string;
  zipCode?: string;
  Channels: string[];
  descStr: string;
}

export interface EntryFormPorps{
  isFirmShow?: boolean;
  isProductShow?: boolean;
  isFromShow?: React.ReactNode;
}

const ManufacturerEntryForm: FC<EntryFormPorps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isFirmShow, isProductShow, isFromShow } = props;
  const { state: { navList } } = useContext(Context);
  const [channels, setChannelsList] = useState<{ value: string; text: string; }[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    let list:ChannelType[] = [];
    navList.map((item) => {
      if (isFirmShow && item.name === '厂商名录') {
        list = item.children || [];
      } else if (isProductShow && item.name === '产品名录') {
        list = item.children || [];
      }
    });
    const channelsList = list.sort((a: ChannelType, b: ChannelType) => (a.orderIndex || 0) - (b.orderIndex || 0))
      .map((item: ChannelType) => ({ value: item.id, text: item.name }));

    setChannelsList(channelsList);
    form.resetFields();
  }, [isFirmShow, isProductShow, navList, form]);

  const onFinish = async (values: FormProps) => {
    const channList = values.Channels.map((item: string) => ({ id: item }));
    if (isFirmShow) {
      values.type = '厂商';
    } else if (isProductShow) {
      values.type = '产品';
    }
    const res = await axios.put('/api/entry', {
      ...values,
      Channels: channList,
    });
    if (res.status === 200) {
      const { status } = res.data;
      if (status === 'ok') {
        message.success('申请提交成功，请等待管理员回复。');
      } else {
        message.warn(res.data.message);
      }
    }
  };
  const onReset = () => {
    form.resetFields();
    if (typeof isFromShow === 'function') {
      isFromShow();
    }
  };

  return (
    <Form
      style={{ marginTop: 50 }}
      {...layout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={({ errorFields }) => {
      // 提交失败的回调
        const msg = errorFields[0].errors[0] || '请正确填写表单';
        message.warn(msg);
      }}
    >
      <Form.Item
        name="corporateName"
        label="单位名称"
        rules={[{ required: true, message: '请填写单位名称' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="contacts"
        label="联系人"
        rules={[{ required: true, message: '请填写联系人' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="contactsMobile"
        label="手机号"
        rules={[
          { required: true, message: '请填写手机号' },
          {
            pattern: new RegExp(
              /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
            ),
            message: '手机号格式有误',
          }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="tel"
        label="电话"
        rules={[
          { required: true, message: '请填写电话' },
          {
            pattern: new RegExp(/^(0\d{2,3})?-?(\d{7,8})$/),
            message: '电话格式有误',
          }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="邮箱"
        rules={[
          { required: true, message: '请填写邮箱' },
          {
            type: 'email',
            message: '邮箱格式有误',
          }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="website"
        label="官方网站"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        label="地址"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="zipCode"
        label="邮编"
        rules={[
          {
            pattern: new RegExp('^[1-9]\\d{5}$'),
            message: '邮编格式有误',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Channels"
        label="详细类别"
        rules={[{ required: true, message: '请选择详细类别' }]}
      >
        <Select mode="multiple">
          {
            channels.map((item:{value: string, text: string}) => (
              <Option key={item.value} value={item.value}>
                {item.text}
              </Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="descStr"
        label="申请描述"
        rules={[
          { required: true, message: '请填写申请描述' },
          {
            pattern: new RegExp(/^[\S\s]{0,200}$/),
            message: '输入的字符过长',
          },
        ]}
      >
        <Input.TextArea
          showCount
          maxLength={200}
        />
      </Form.Item>
      <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
        <Button style={{ width: 120, marginTop: '1rem' }} type="primary" htmlType="submit" shape="round">
          提交
        </Button>
        <Button
          htmlType="button"
          style={{ marginLeft: 20, width: 120 }}
          type="primary"
          ghost
          onClick={onReset}
          shape="round"
        >
          取消
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManufacturerEntryForm;
