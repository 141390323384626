/* eslint-disable react-hooks/exhaustive-deps */
/** !
 * @description: Tab
 * @author: zpl
 * @Date: 2020-09-23 14:47:40
 * @LastEditTime: 2020-09-23 14:47:45
 * @LastEditors: zpl
 */
import React, { FC, useEffect, useState } from 'react';
import { Tabs, List, Skeleton } from 'antd';

import './index.less';

const { TabPane } = Tabs;

const defaultValues = [{
  nav: {
    id: -1,
    name: '省内动态',
    enName: 'sndt',
  },
  list: [],
}, {
  nav: {
    id: -1,
    name: '国内动态',
    enName: 'gndt',
  },
  list: [],
}, {
  nav: {
    id: -1,
    name: '国际动态',
    enName: 'gjdt',
  },
  list: [],
}];

interface PropsType {
  dataList: {
    nav:{id:number; name:string; enName:string;};
    list:{ title:string; conDate:string; id:string }[]
  }[];
}

const NewsTab: FC<PropsType> = ({ dataList = defaultValues }: PropsType) => {
  const [more, setMore] = useState('sndt');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataList && dataList.length) {
      if (dataList[0].nav.id !== -1) {
        setLoading(false);
      }
    }
  }, [dataList]);

  const currentList = dataList && dataList.length ? dataList : defaultValues;
  return (
    <Tabs
      className="newsTabCon"
      tabBarExtraContent={<a href={`/channel/${more}`}>更多</a>}
      onChange={(activeKey: string) => {
        setMore(activeKey);
      }}
    >
      {
          currentList.map((tab: {
            nav:{id:number; name:string; enName:string;},
            list:{ title:string; conDate:string; id:string }[]
          }, index: number) => (
            <TabPane tab={tab.nav.name} key={tab.nav.enName || index}>
              <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
                <List
                  grid={{
                    gutter: 24,
                    xs: 1,
                    column: 2,
                  }}
                  itemLayout="horizontal"
                  dataSource={tab.list}
                  renderItem={(item) => {
                    const date = new Date(item.conDate.replace(/\s/g, 'T'));
                    const mStr = `${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}月`;
                    const dStr = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
                    return (
                      <div className="listStyle">
                        <List.Item>
                          <List.Item.Meta
                            avatar={(
                              <div>
                                <div className="day">{dStr}</div>
                                <div className="month">{mStr}</div>
                              </div>
                                )}
                            title={<a href={`/channel/${tab.nav.enName}#${item.id}`}>{item.title}</a>}
                          />
                        </List.Item>
                      </div>
                    );
                  }}
                />
              </Skeleton>
            </TabPane>
          ))
      }
    </Tabs>
  );
};

export default NewsTab;
