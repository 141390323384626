/* eslint-disable array-callback-return */
/** !
 * @description: 右上角快速入口
 * @author: zpl
 * @Date: 2020-09-20 14:50:58
 * @LastEditTime: 2020-09-20 16:35:19
 * @LastEditors: zpl
 */
import React, { FC } from 'react';

interface PropsType {
  entranceList: EntranceType[];
  nvaName: EntranceType[];
}

const Entrance: FC<PropsType> = ({ entranceList, nvaName }: PropsType) => (

  <ul className="entrance">
    {
       // eslint-disable-next-line react/prop-types
       nvaName.length > 0 && nvaName.map((item: EntranceType) => (
         <li key={item.id}>
           <a href={item.url} target={item.url?.startsWith('http') ? '_black' : '_self'}>{item.text}</a>
         </li>
       ))
    }
    {
      // eslint-disable-next-line consistent-return
      entranceList.length > 0 && entranceList.map((item: EntranceType) => (
        <li key={item.id}>
          <a href={item.url} target={item.url?.startsWith('http') ? '_black' : '_self'}>{item.text}</a>
        </li>
      ))
    }
  </ul>
);

export default Entrance;
