/** !
 * @description: 面包屑导航
 * @author: zpl
 * @Date: 2020-09-24 10:29:11
 * @LastEditTime: 2020-09-24 10:29:35
 * @LastEditors: zpl
 */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';

import Context from '@/store/context';
import { indexNav } from '@/constant';

import './index.less';

const BreadNav: FC = () => {
  const [show, setShow] = useState(false);
  const { state } = useContext(Context);
  const { navPath } = state;

  useEffect(() => {
    if (!navPath.length || (navPath.length === 1 && navPath[0].name === '首页')) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [navPath]);

  if (!navPath.find((nav) => nav.name === '首页')) {
    navPath.unshift(indexNav);
  }

  if (show) {
    return (
      <div className="breadCon">
        <Breadcrumb separator=">">
          {
            navPath.map((nav) => (
              <Breadcrumb.Item key={nav.id}>
                <a href={nav.enName === 'index' ? '/' : `/channel/${nav.enName}`}>{nav.name}</a>
              </Breadcrumb.Item>
            ))
          }
          {
            window.location.hash && (<Breadcrumb.Item>正文</Breadcrumb.Item>)
          }
        </Breadcrumb>
      </div>
    );
  }
  return <></>;
};

export default BreadNav;
