/** !
 * @description: 培训入口
 * @author: zpl
 * @Date: 2020-10-07 14:43:10
 * @LastEditTime: 2020-10-07 14:43:42
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import moreImg from '@images/more.png';
import './index.less';

interface PropsType {
  dataList: {
    id: string;
    title: string;
    conDate: string;
    enName: string;
    Channels: any[];
  }[],
}
const TrainingEntrance: FC<PropsType> = ({ dataList = [] }:PropsType) => {
  // 使用默认数据，以保证无数据的情况下布局不被破坏
  const newList = dataList;
  const n = 2 - newList.length;
  for (let i = 0; i < n; i += 1) {
    newList.push({
      id: `${i}`,
      title: '敬请期待',
      conDate: '',
      enName: '',
      Channels: [],
    });
  }
  return (
    <Row className="TrainCon">
      <Col flex="auto">
        <div>
          <Row gutter={20}>
            {
              newList.length > 0 && newList.map((item) => (
                <Col key={item.id} xs={24} sm={12}>
                  <div className="card">
                    <div className="title">{item.title}</div>
                    <Row>
                      <Col className="date" flex="auto">{item.conDate ? moment(item.conDate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                      {
                        item.title !== '敬请期待'
                          ? (
                            <Col className="reg" flex="80px">
                              <a href={`/channel/${item.Channels[0].enName}#${item.id}`} target="_black">立即报名</a>
                            </Col>
                          )
                          : <Col><div><a>&nbsp;</a></div></Col>
                      }
                    </Row>
                  </div>
                </Col>
              ))
            }
          </Row>
        </div>
      </Col>
      <Col className="more" flex="36px">
        <a href="/channel/aqyspx">
          更多培训
          <img src={moreImg} alt="更多培训" />
        </a>
      </Col>
    </Row>
  );
};

export default TrainingEntrance;
