/** !
 * @description: 培训报名申请
 * @author: zpl
 * @Date: 2020-11-07 13:04:06
 * @LastEditTime: 2020-11-07 13:04:16
 * @LastEditors: zpl
 */
import React, { FC, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { trainingReg } from '@/api';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface PropsType {
  TrainingId: string;
}

const ProvideTraining: FC<PropsType> = ({ TrainingId }: PropsType) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ TrainingId });
  }, [form, TrainingId]);

  const onFinish = async (values: { name: string; mobile: string; email: string; comp: string; }) => {
    const { name, mobile, email, comp } = values;
    const res = await trainingReg(name, mobile, email, comp, TrainingId);
    if (res === '') {
      message.info('报名成功，请注意收取回复邮件。');
    } else if (res === 'Internal Server Error') {
      message.error('报名失败，请稍后再试');
    } else {
      message.warn(res);
    }
  };

  return (
    <Form {...formItemLayout} form={form} onFinish={onFinish}>
      <Form.Item
        hidden
        name="TrainingId"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="真实姓名"
        rules={[
          {
            required: true,
            message: '请填写真实姓名',
          },
        ]}
      >
        <Input maxLength={10} />
      </Form.Item>
      <Form.Item
        name="mobile"
        label="手机号"
        rules={[
          {
            pattern: new RegExp(
              /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
            ),
            message: '手机号码格式有误',
          },
          {
            required: true,
            message: '请填写手机号',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="邮箱"
        rules={[
          {
            type: 'email',
            message: '填写的邮箱格式有误',
          },
          {
            required: true,
            message: '请填写邮箱信息',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="comp"
        label="公司"
        rules={[
          {
            required: true,
            message: '请填写公司信息',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button style={{ width: 120 }} type="primary" htmlType="submit" shape="round">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProvideTraining;
