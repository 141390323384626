import React, { FC, Reducer, useEffect, useReducer } from 'react';
import { Layout } from 'antd';
import { BrowserRouter, Route } from 'react-router-dom';

import './index.less';
import GlobalHeader from '@components/GlobalHeader/index';
import GlobalFooter from '@components/GlobalFooter/index';

import HomePage from '@/pages/HomePage';
// import ContentPage from '@/pages/ContentPage';
import OtherPage from '@/pages/OtherPage';

import Context from '@/store/context';
import { commonReducer } from '@/store/reducer';
import { getCommonSettings, getNav } from '@/api';
import { getNavPath } from '@/utils';
import { indexNav } from '@/constant';
import BreadNav from '@/components/BreadNav';

const { Header, Footer, Content } = Layout;

const CommonLayout: FC = () => {
  const [state, dispatch] = useReducer<Reducer<CommonStateType, CommonReaducerType>>(
    commonReducer,
    {
      navList: [],
      navPath: [],
      commonSettings: [],
    },
  );

  useEffect(() => {
    (async () => {
      // 获取菜单
      const list = await getNav();
      const { pathname } = window.location;
      if (pathname !== '/') {
        const enName = pathname.split('/')[2];
        const path = getNavPath(list, enName);
        dispatch({ type: 'SET_LIST', navList: list, navPath: path.reverse() });
      } else {
        dispatch({ type: 'SET_LIST', navList: list, navPath: [indexNav] });
      }

      // 获取全局公共配置
      const commonSettings = await getCommonSettings();
      dispatch && dispatch({ type: 'SET_COMMON_SETTINGS', commonSettings });
    })();
    return () => {
      // cleanup
    };
  }, []);

  return (
    <BrowserRouter>
      <Layout>
        <Context.Provider value={{ state, dispatch }}>
          <Header><GlobalHeader /></Header>
          <BreadNav />
          <Content className="mainCon">
            <Route key="/" path="/" component={HomePage} exact />
            <Route key="/index" path="/index" component={HomePage} exact />
            {/* <Route key="/content" path="/content/:channelenname/:id" component={ContentPage} exact /> */}
            <Route key="/channel" path="/channel/:channelenname" component={OtherPage} />
          </Content>
          <Footer><GlobalFooter /></Footer>
        </Context.Provider>
      </Layout>
    </BrowserRouter>
  );
};

export default CommonLayout;
