/** !
 * @description: 申请加入个人会员
 * @author: zpl
 * @Date: 2020-11-07 11:56:21
 * @LastEditTime: 2020-11-07 11:56:40
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Button, Form, Input, Radio, message } from 'antd';
import { indivicSub } from '@/api';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const onFinish = async (values: {
  name:string,
  sex:number,
  idNumber:string,
  mobile:string,
  email:string,
  profession:string,
  website:string,
  intro:string,
}) => {
  const res = await indivicSub(values);
  if (res === '') {
    message.info('申请提交成功，审核人员将会您联系');
  } else if (res === 'Internal Server Error') {
    message.error('申请失败，请稍后再试');
  } else {
    message.warn(res);
  }
};

const JoinUsForIndivic: FC = () => (
  <Form
    {...formItemLayout}
    onFinish={onFinish}
    initialValues={{
      sex: 1,
    }}
  >
    <Form.Item
      name="name"
      label="真实姓名"
      rules={[
        {
          required: true,
          message: '请填写真实姓名',
        },
      ]}
    >
      <Input maxLength={10} />
    </Form.Item>
    <Form.Item
      name="sex"
      label="性别"
    >
      <Radio.Group>
        <Radio value={1}>男</Radio>
        <Radio value={2}>女</Radio>
      </Radio.Group>
    </Form.Item>
    <Form.Item
      name="idNumber"
      label="身份证号码"
      rules={[
        {
          required: true,
          message: '请填写身份证号码',
        },
        {
          // eslint-disable-next-line max-len
          pattern: new RegExp(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/),
          message: '身份证号码格式有误',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="mobile"
      label="手机号"
      rules={[
        {
          pattern: new RegExp(
            /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
          ),
          message: '手机号码格式有误',
        },
        {
          required: true,
          message: '请填写手机号',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="email"
      label="邮箱"
      rules={[
        {
          required: true,
          message: '请填写邮箱',
        },
        {
          type: 'email',
          message: '填写的邮箱格式有误',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="profession"
      label="职业"
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="website"
      label="个人网站"
      rules={[
        {
          type: 'url',
          message: '网站格式有误',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="intro"
      label="个人介绍"
      rules={[
        {
          required: true,
          message: '请填写个人介绍',
        },
      ]}
    >
      <Input.TextArea autoSize={{ minRows: 5 }} showCount maxLength={200} />
    </Form.Item>
    <Form.Item {...tailLayout}>
      <Button style={{ width: 120 }} type="primary" htmlType="submit" shape="round">
        提交
      </Button>
    </Form.Item>
  </Form>
);

export default JoinUsForIndivic;
