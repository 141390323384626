/* eslint-disable max-len */
import React from 'react';
import './index.less';
import logo from '@images/logo.png';

const GlobalFooter = () => (
  <div className="global-footer">
    <div className="first">
      <img src={logo} alt="" />
      <div className="textContent">
        <p>电话：029-8885-9176</p>
        <p>传真：029-8885-9176</p>
        <p>邮箱：409015382@qq.com</p>
        <p>地址：西安市高新区茶张路一号省信息化中心17层</p>
      </div>
      <div className="imgs">
        <div>
          <img src="/images/auditorium.png" alt="" />
          <div>网安风向标</div>
        </div>
        <div>
          <img src="/images/public.png" alt="" />
          <div>微信公众号</div>
        </div>
      </div>
    </div>
    <div className="second">
      <div>
        Copyright ©陕西省信息网络安全协会 All Rights Reserved. &nbsp;&nbsp;未经许可 不得转载 &nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" rel="noreferrer">陕ICP备17015011号-1</a>
        &nbsp;&nbsp;技术支持：西安云适配网络科技有限公司
      </div>
    </div>
  </div>
);

export default GlobalFooter;
