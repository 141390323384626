/* eslint-disable react/require-default-props */
/** !
 * @description: 左下角快捷入口
 * @author: zpl
 * @Date: 2020-09-25 11:58:52
 * @LastEditTime: 2020-09-25 11:59:16
 * @LastEditors: zpl
 */
import React, { FC } from 'react';
import { Card, List, Image } from 'antd';

import { defImg, sourceUrl } from '@/constant';
import './index.less';
/**
 * 从配置信息中过滤入口配置，并转换为图片需要的格式
 *
 * @param {ChannelSettingType[]} settings
 * @return {*}  {PicListType[]}
 */
const getList = (settings: ChannelSettingType[]): PicListType[] => {
  const list = settings
    .filter((setting) => setting.group === '快捷入口')
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map((setting) => ({
      id: setting.id,
      text: setting.title,
      url: setting.link,
      img: sourceUrl + setting.pic,
    }));
  return list;
};

interface PropsType {
  settings: ChannelSettingType[];
  column?: number;
}

const QuickEntry: FC<PropsType> = ({ settings, column = 5 }: PropsType) => {
  const list = getList(settings);
  return (
    <List
      className="quickEntryCon"
      grid={{
        gutter: 20,
        xs: 3,
        column,
      }}
      dataSource={list}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <Card>
            <a href={item.url}>
              <Image
                src={item.img}
                preview={false}
                fallback={defImg}
              />
              <div>
                {item.text}
              </div>
            </a>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default QuickEntry;
